// Import bootstrap react components
import { Button, Card, Container, Row, Col } from "react-bootstrap";
// Import framer motion
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import CountDown from "../components/functions/CountDown";
import React, { useState, useEffect } from 'react';

function BuyProperty() {
  // Toggle the like button state
  function like(e) {
    e.target.classList.toggle("fa-solid");
    e.target.classList.toggle("fa-regular");
    e.target.classList.toggle("text-danger");
  }

  // Function to handle buying with ETH
  function buyWithETH(property) {
    // Implement the logic to buy the asset with ETH
    alert(`Buying ${property.title} for ${property.currentBid} ETH`);
    // You can add additional logic here, such as calling an API or a smart contract
  }
  // Card data
  const cardData = [
    {
      imgSrc: require("../images/properties/pexels-rachel-claire-8112843 1.webp"),
      title: "Wish house",
      agency: "@UA real estate agency",
      remainingTime: { h: 23, m: 6, s: 1 },
      currentBid: "16.62",
    },
    {
      imgSrc: require("../images/properties/house_big.webp"),
      title: "Blue Sky",
      agency: "@ERA Link Real Estate",
      remainingTime: { h: 23, m: 16, s: 11 },
      currentBid: "17.31",
    },
    // Add more card data as needed
  ];

  return (
    // Start properties
    <div className="properties">
      <Container>
        <AnimationTitles
          className="title mx-auto"
          title="Featured Properties"
        />
        {/* Start tabs */}
        <div className="tabs d-flex justify-content-start justify-content-sm-center align-items-center flex-nowrap w-lg-50">
          {/* Placeholder for Swiper or tabs content */}
        </div>
        {/* End tabs */}
        {/* Start cards */}
        <motion.div
          initial={{ x: -80 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="d-flex flex-wrap gap-3 mt-4">
            {cardData.map((card, index) => {
              return (
                <div key={index} className="d-flex flex-column" style={{ flexBasis: 'calc(50% - 15px)', maxWidth: 'calc(50% - 15px)' }}>
                  <Card className="bg-black-100 rounded">
                    <div className="position-relative">
                      <Card.Img
                        variant="top"
                        alt="img"
                        src={card.imgSrc}
                        className="card-img"
                      />
                      <i className="fa-regular fa-heart like" onClick={like}></i>
                    </div>
                    <Card.Body className="p-2">
                      <h5 className="mt-2 text-white fw-normal">{card.title}</h5>
                      <p className="gray-90">{card.agency}</p>
                      <Row className="mt-2">
                        {/* Column 1: Remaining Time */}
                        <Col xs={12} md={4} className="d-flex flex-column justify-content-between">
                          <div className="d-flex flex-column mb-2">
                            <CountDown {...card.remainingTime} />
                            <span className="gray-90">Remaining Time</span>
                          </div>
                        </Col>

                        {/* Column 2: Button */}
                        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                          
                        </Col>

                        {/* Column 3: ETH Price and Button */}
                        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                          <div className="text-center mb-2">
                            <span className="text-white">Price: {card.currentBid} ETH</span>
                          </div>
                          <Button 
                            className="btn-buy-eth mt-2" 
                            variant="primary"
                            onClick={() => buyWithETH(card)} // Add onClick handler
                          >
                            BUY
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </motion.div>
        {/* End cards */}
      </Container>
    </div>
    // End properties
  );
}

export default BuyProperty;
