import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/navbar/Navbar";
import Footer from "./pages/Footer";
import Partners from "./pages/Partners";
import BuyProperties from "./pages/BuyProperties";
import Properties from "./pages/Properties";

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Partners />
        <BuyProperties />
        <Properties />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
